import React, {useEffect, useState} from "react";
import {ClubLogo} from "../../ClubLogo";

export const MatchCard = ({
                              match_id,
                              tournament,
                              matchTime,
                              homeTeamName,
                              awayTeamName,
                              homeTeamScore = "-",
                              awayTeamScore = "",
                              diff,
                              batnow,
                              complete = "false",
                              watchalongscheduled = false,
                              stream = "false",
                              isTraderEnabled = false,
                              isSquaresEnabled = false,
                          }) => {

    const [squaresForThisMatch, setSquaresForThisMatch] = useState(false);

    tournament = tournament.replace("Indian Premier League", "IPL 2025");
    const handleMatchClick = (matchId, localTime, complete) => {
        if (
            localTime === "IN-PLAY" ||
            localTime === "Match Complete" ||
            complete === "true"
        ) {
            window.location = "/matchdetail?id=" + matchId + "&status=" + complete;
        }
    };

    const playSquaresForThisMatch = async (match_id) => {
        const response = await fetch(`${process.env.API_URL}/squaresactivematches/${match_id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY,
            },
        });
        if (response.status !== 200) {
            setSquaresForThisMatch(false)
        }
        const data = await response.json();
        setSquaresForThisMatch(data);
    }

    useEffect(() => {
        playSquaresForThisMatch(match_id);
    }, []);

    return (
        <div
            className={`MatchCard ${
                matchTime === "IN-PLAY" ||
                matchTime === "Match Complete" ||
                complete === "true"
                    ? "cursor-pointer"
                    : ""
            }`}
            onClick={() => handleMatchClick(match_id, matchTime, complete)}
        >
            <div className="header h-[30px] md:h-[40px]">
                <div className="title truncate !text-[12px]">{tournament}</div>
                <div
                    className={`schedule mr-[10px] ${
                        matchTime === "IN-PLAY" ? "live" : ""
                    }`}
                >
                    {matchTime}
                    {matchTime === "IN-PLAY" ? (
                        <div className="live-indicator">
                            <div className="inner"></div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="body">
                <div
                    className="homeTeam h-[60px]"
                    style={{
                        backgroundColor:
                            matchTime === "Match Complete" || complete === "true"
                                ? !diff?.includes(homeTeamName) && diff?.includes("have won")
                                    ? "#ddd4e9"
                                    : "#FFFFFF"
                                : batnow === "" || homeTeamName === batnow
                                    ? "#FFFFFF"
                                    : "#ddd4e9",
                        borderRight: homeTeamName === batnow ? "3px solid red" : "none",
                    }}
                >
                    <div className="top">
                        <div className="logo flex justify-center items-center pt-[6px]">
                            <ClubLogo teamName={homeTeamName} width={36} height={36}/>
                        </div>
                        <div className="scoreline">{homeTeamScore}</div>
                    </div>
                    {
                        <div className="font-chakra text-[12px] !font-bold py-[4px] normal-case truncate">
                            {homeTeamName}
                        </div>
                    }
                </div>
                <div
                    className="awayTeam h-[60px]"
                    style={{
                        backgroundColor:
                            matchTime === "Match Complete" || complete === "true"
                                ? !diff?.includes(awayTeamName) && diff?.includes("have won")
                                    ? "#ddd4e9"
                                    : "#FFFFFF"
                                : batnow === "" || awayTeamName === batnow
                                    ? "#FFFFFF"
                                    : "#ddd4e9",
                        borderRight: awayTeamName === batnow ? "3px solid red" : "none",
                    }}
                >
                    <div className="top">
                        <div className="logo flex justify-center items-center pt-[6px]">
                            <ClubLogo teamName={awayTeamName} width={36} height={36}/>
                        </div>
                        <div className="scoreline">{awayTeamScore}</div>
                    </div>
                    <div className="font-chakra text-[12px] !font-bold py-[4px] normal-case truncate">
                        {awayTeamName}
                    </div>
                </div>
            </div>
            <div className="footer justify-between">
                {matchTime === "IN-PLAY" && complete === "true" ? (
                    <></>
                ) : (
                    <div className="flex items-center w-[50%] ml-[8px]">
                        <div className="w-[200px] font-chakra text-[12px] block truncate">
                            {matchTime === "IN-PLAY" ? (
                                <>
                                    {watchalongscheduled === "false" ? (
                                        (isTraderEnabled || (isSquaresEnabled && squaresForThisMatch)) &&
                                        (
                                            <div
                                                className="w-full h-[40px] flex flex-row gap-[4px] items-center font-chakra text-[12px]">
                                                <img src="/img/games.svg" alt=""/>
                                                <span>PLAY GAMES</span>
                                            </div>
                                        )
                                    ) : (
                                        <span>
                      <img
                          style={{border: "3px solid green"}}
                          className="pr-[10px]"
                          alt="Vector"
                          src="/img/camera.svg"
                      />
                      WATCH ALONG LIVE
                    </span>
                                    )}
                                </>
                            ) : matchTime === "Match Complete" || complete === "true" ? (
                                <>
                                    {complete === "true" ? (
                                        <span>{diff.substring(17)}</span>
                                    ) : (
                                        <span>MATCH COMPLETE</span>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="font-chakra text-[12px] w-full">
                                        {diff === "Delayed" ? "DELAYED" : <>STARTS IN {diff}</>}
                                    </div>
                                    <div className="w-full flex flex-row justify-between items-center">
                                        <div
                                            className="flex flex-row justify-center items-center gap-[4px] font-chakra text-[12px] text-[#32f78c]">
                                            {watchalongscheduled !== "false" ? (
                                                <>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M17 10.5V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3.5l4 4v-11z"
                                                        />
                                                    </svg>
                                                    WATCH-ALONG SCHEDULED
                                                </>
                                            ) : stream === "true" ? (
                                                <>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M17 10.5V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3.5l4 4v-11z"
                                                        />
                                                    </svg>
                                                    LIVESTREAM SCHEDULED
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className={`action truncate w-[50%]`}>
                    <div className="justify-self-end px-[13px] py-[11px]">
                        <span>MATCH</span>
                    </div>
                    {(matchTime === 'IN-PLAY' || matchTime === 'Match Complete') && (
                        <div className="prompt text-[#1b062f]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.78em"
                                height="1em"
                                viewBox="0 0 16 9"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"
                                />
                                <path
                                    fill="currentColor"
                                    d="M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71s.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default MatchCard;
